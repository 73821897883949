import * as React from "react";
import Notifications from "./notifications";
import Inbox from "./inbox";
import Icon from "../icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  id: string;
  avatar: string;
  fullName: string;
  flash: {
    [key: string]: string;
  };
  newActivities: Activity[];
  notifications: NotificationObject[];
  toggleSidePanel: () => void;
  showActivities: boolean;
  showProfile: boolean;
};

type Activity = {
  id: string;
  message: string;
  ending: string;
  createdAt: string;
};

type NotificationObject = {
  id: string;
  subject: string;
  body: string;
  unread: boolean;
  link: string;
  createdAt: string;
};

export default (props: Props) => (
  <View newActivityCount={props.newActivities.length} {...props} />
);

const View = ({
  id,
  avatar,
  fullName,
  notifications,
  toggleSidePanel,
  newActivityCount,
  showActivities,
  showProfile
}: Props & { newActivityCount: number }) => (
  <ul className="nav-right">
    <li className="user-profile dropdown">
      <a
        href=""
        className="dropdown-toggle test-user-dropdown-toggle"
        data-toggle="dropdown"
      >
        <img className="profile-img img-fluid" src={avatar} alt="" />
        <div className="user-info">
          <span className="name pdd-right-5">{fullName}</span>
          <Icon icon="angle-down" />
        </div>
      </a>
      <ul className="dropdown-menu">
        {showProfile && (
          <li>
            <a href={`/users/${id}`}>
              <Icon icon="user" />
              <span className="pdd-left-10">Profile</span>
            </a>
          </li>
        )}
        <li>
          <Inbox />
        </li>
        <li role="separator" className="divider" />
        <li>
          <a rel="nofollow" data-method="delete" href="/users/sign_out">
            <Icon icon="power-off" />
            <span className="pdd-left-10">Logout</span>
          </a>
        </li>
      </ul>
    </li>
    <Notifications notifications={notifications} />
    {showActivities && (
      <li className="notifications dropdown">
        {newActivityCount !== 0 && (
          <span className="counter">{newActivityCount}</span>
        )}
        <a href="#" onClick={toggleSidePanel}>
          <Icon icon="align-right" />
        </a>
      </li>
    )}
  </ul>
);
